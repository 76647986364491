import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Slide from 'react-reveal/Slide';
import Scroll from 'react-scroll';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import Color from 'styles/Color';
import Screens from 'styles/Screens';
import logger from 'utils/logger';

import {
  Container
} from 'components/GridSystem';

import {
  BigIdea,
  Marker,
  PrettyTitle,
  SectionLabel
} from 'components/Text';

import {
  Cover,
  FeaturedEntry
} from 'components/Sections';

import {
  ProximityLogo
} from 'components/Elements';

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'


const Element = Scroll.Element;

class Index extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      section: {
        landing: true
      },
      down: true,
      up: false
    };
    this.handleToggleSection = this.handleToggleSection.bind(this);
    this.handleMouseScrollTo = this.handleMouseScrollTo.bind(this);
  }

  scrollToTop () {
    Scroll.animateScroll.scrollToTop();
  }

  handleToggleSection (from, to, direction) {
    let { section } = this.state;
    logger.debug(`handleToggleSection > section going ${direction}`, section);
    // home to 1 {
    //  home up
    //    1 up
    //    -
    // 1 to home
    //    1 down
    //    h0me down
    // }
    // const sections = Object.entries(section)[1];
    // sections.forEach(check) => {
    //   logger
    // };
    section[from] = false;
    section[to] = true;
    this.setState({
      next: direction === `next` ? true : false,
      prev: direction === `prev` ? true : false
    });
    this.setState({
      section
    });
  }

  handleMouseScrollTo (target) {
    logger.debug(`scrollTo (${target})`);
    Scroll.scroller.scrollTo(target, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: 50, // Scrolls to element + 50 pixels down the page
    });
  }

  render () {
    const { data } = this.props;
    const featured = [
      {
        id: `slide-1`,
        background: {
          image: `https://images.ctfassets.net/fiz3jwws2um7/3z0pE2k7v9j1lYa1aorhSN/ed9281298a90df75772061ee29dfbda1/Hugeinc_Predictions_Hugeinc_1920x1080_d.jpg?w=1600`,
          size: `cover`
        },
        type: `Insights`,
        title: <span>The not so <mark>ART</mark> <i>of</i> <strong>Branding.</strong></span>,
        text: `P&G’s SK-II partnered with Huge to redefine the future of retail with a new experiential smart store.`
      },
      {
        id: `slide-2`,
        background:{
          image: `https://media.giphy.com/media/BHNfhgU63qrks/giphy.gif`,
          size: `cover`
        },
        type: `Insights`,
        title: <span>The not so <mark>ART</mark> <i>of</i> <strong>Branding.</strong></span>,
        text: `P&G’s SK-II partnered with Huge to redefine the future of retail with a new experiential smart store.`
      }
    ];
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.sectionLanding} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <ScrollLock isActive={true} />
        <Element name={`top`} />
        <background style={{
          position: `fixed`,
          top: `0`,
          left: `0`,
          width: `100vw`,
          height: `100vh`
        }}>
          { featured && featured.map((feature, i) => {
            const n = i + 1;
            logger.debug(`this.state.section[featuredArticle${n}]`, this.state.section[`featuredArticle${n}`]);
            return (
              <article key={`bg-${feature.id}`} style={{
                position: `fixed`,
                top: `0`,
                left: `0`,
                width: `100vw`,
                opacity: this.state.section[`featuredArticle${n}`] ? `0` : `0`,
                transition: `all 900ms cubic-bezier(.1,.55,.4,1)`
              }}>
                
                    <FeaturedEntry
                      background={feature.background}
                    >
                    </FeaturedEntry>
                
              </article>
            );
          })}
        </background>
        <Slide collapse top={this.state.section[`next`]} bottom={this.state.section[`prev`]} when={this.state.section[`landing`]}>
          <ProximityLogo />
        </Slide>
        { featured && featured.map((feature, i) => {
          const n = i + 1;
          return (
            <article key={feature.id}>
              <Slide collapse left when={this.state.section[`featuredArticle${n}`]}>
                  <FeaturedEntry
                  >
                    <Marker n={n} />
                    <SectionLabel label={feature.type} />
                    <PrettyTitle>The not so <mark>ART</mark> <i>of</i> <strong>Branding.</strong></PrettyTitle>
                    <hr />
                    <p>{feature.text}</p>
                  </FeaturedEntry>
              </Slide>
            </article>
          );
        })}
        <section style={{position: `fixed`, top: `0`, left: `0`, width:`100vw`}}>
          <a onClick={e => this.handleMouseScrollTo(`myScrollToElement`)}>
            Test Scroll
          </a><span> | </span>
          <a onClick={e => this.handleToggleSection(`landing`, `featuredArticle1`, `next`)}>home => 1</a><span> | </span>
          <a onClick={e => this.handleToggleSection(`featuredArticle1`, `featuredArticle2`, `next`)}>1 => 2</a><span> | </span>
          <a onClick={e => this.handleToggleSection(`featuredArticle2`, `featuredArticle1`, `prev`)}>2 = 1</a><span> | </span>
          <a onClick={e => this.handleToggleSection(`featuredArticle1`, `landing`, `prev`)}>1 => home</a><span> | </span>
        </section>


        <Element name={`myScrollToElement`}>
        <Cover>
          
          <SectionLabel label={`Superhero`} />

          <SectionLabel label={`Superhero`} />
          <BigIdea>y <b style={{fontWeight: `900`}}>THINKLO</b> design <br/>Tt system</BigIdea>
          <BigIdea>Single line</BigIdea>
          <SectionLabel label={`Ultrawide Test`} />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lorem felis, congue quis dolor malesuada, dignissim pellentesque dui. Integer faucibus, odio vel consequat aliquam, massa metus ornare lectus, ac elementum lectus mauris eget enim. In sodales cursus nulla et efficitur. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
          <p>Pellentesque in vulputate dui. In hac habitasse platea dictumst. Quisque quis volutpat lacus, quis congue lacus. Cras nec lacinia enim. Mauris enim lacus, egestas sed malesuada maximus, commodo sit amet elit. Curabitur mattis quam et lacinia vulputate. Integer id tortor vel enim eleifend convallis vel vel urna. Ut ultrices ultrices nibh, in congue mi luctus pulvinar.</p>
          <div onClick={e => this.handleMouseScrollTo(`top`)}>
            Scroll Top
          </div>
          <SectionLabel label={`Color`} />


          <SectionLabel label={`Headings`} />
          <h1>H1 Header</h1>
          <h2>H2 Header</h2>
          <h3>H3 Header</h3>
          <h4>H4 Header</h4>
          <h5>H5 Header</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lorem felis, congue quis dolor malesuada, dignissim pellentesque dui. Integer faucibus, odio vel consequat aliquam, massa metus ornare lectus, ac elementum lectus mauris eget enim.</p>
        </Cover>
        </Element>
        {/*
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          )
        })}

        */}
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
